<template>
  <div>
    <div class="imgView">
       <div class="imgWrap">
         <img v-lazy="imgUrl" />
       </div>
       <div class="btnBox">
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-arrow-left"
            size="mini"
            @click="prePage"
            >上一页</el-button
          >
          <el-button type="primary" size="mini" @click="nextPage"
            >下一页<i class="el-icon-arrow-right el-icon--right"></i
          ></el-button>
        </el-button-group>
        <!-- <div class="numBox">
          {{ pageNum }} / {{ pageTotalNum }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import MiniBanner from "@/components/miniBanner/index";
import { mapActions } from "vuex";
// import pdf from "vue-pdf";
import Vue from 'vue';
import { Lazyload } from 'vant';

Vue.use(Lazyload);

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});

export default {
  name: "MiniRegistrationGuide",
  data() {
    return {
      imgUrl: "",
      currentNum: 0,
      address: 'http://101.200.177.46:8092/static/handbook/',
      imgList: [
        "handbook0.jpg", "handbook1.jpg", "handbook2.jpg", "handbook3.jpg", "handbook4.jpg", "handbook5.jpg",
        "handbook6.jpg", "handbook7.jpg", "handbook8.jpg", "handbook9.jpg", "handbook10.jpg", "handbook11.jpg",
        "handbook12.jpg", "handbook13.jpg", "handbook14.jpg", "handbook15.jpg", "handbook16.jpg", "handbook17.jpg",
        "handbook18.jpg", "handbook19.jpg", "handbook20.jpg", "handbook21.jpg", "handbook22.jpg", "handbook23.jpg",
        "handbook24.jpg", "handbook25.jpg", "handbook26.jpg", "handbook27.jpg", "handbook28.jpg"
      ]
    };
  },
  components: {
    // MiniBanner,
    // pdf,
  },
  methods: {
    // 上一页
    prePage() {
      if(this.currentNum <= 0) {
        this.currentNum = 0
      }else {
        this.currentNum --
        this.imgUrl = this.address + this.imgList[this.currentNum]
      }
      console.log(this.imgUrl);
    },
    // 下一页
    nextPage() {
      if(this.currentNum >= (this.imgList.length-1)) {
        this.currentNum = this.imgList.length-1
      }else {
        this.currentNum ++
        this.imgUrl = this.address + this.imgList[this.currentNum]
      }
      console.log(this.imgUrl);
    },
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    this.setTitle("会议手册");
    this.imgUrl = this.address + this.imgList[this.currentNum]
  },
};
</script>

<style scoped>

.btnBox {
  text-align: center;
}
.el-button-group {
  margin-top: 15px;
  margin-bottom: 15px;
}
.numBox {
  margin-top: 15px;
  display: none;
}
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 1.5em;
  padding: 25px 30px;
}
.txt img {
  max-width: 100%;
}
.imgWrap {
  width: 100%;
  min-height: calc(585/414*100vw);
}
.imgWrap > img {
  width: 100%;
}
</style>